<template>
  <div class="deal-info-wrap">
    <h2 class="sub-title">成交信息</h2>
    <div :gutter="24" style="margin-top: 16px" class="dealInfoList">
        <div class="item" :span="4" v-for="(item, index) in makeList" :key="index" style="margin-bottom: 20px">
            <v-autocomplete
            v-if="item.value==='isFourSix'"
            v-model="transactionInfo[item.value]"
            :items="[{id:1,name:'是'}, {id:0, name:'否'}]"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="'是否46分成'"
            outlined
            item-text="name"
            item-value="id"
            :dense="true"
            :height="42"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
            no-data-text="暂无数据"
          ></v-autocomplete>
          
          <v-autocomplete
            style="padding: 6px 0"
            v-else-if="item.value==='lecturerAssigned'"
            v-model="transactionInfo[item.value]"
            :items="[{id:true,name:'是'}, {id:false, name:'否'}]"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="'是否外派'"
            outlined
            item-text="name"
            item-value="id"
            :dense="true"
            :height="32"
            :clearable="true"
            disabled
            clear-icon="$clear"
            hide-details="auto"
            no-data-text="暂无数据"
          ></v-autocomplete>

            <v-autocomplete
            v-else-if="item.value==='promotionForm'"
            v-model="transactionInfo[item.value]"
            :items="[{id:'招生协助',name:'招生协助'}, {id:'合伙人自主', name:'合伙人自主'}]"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="'促单形式'"
            outlined
            :disabled="!transactionInfo['economicFlowPartner']"
            item-text="name"
            item-value="id"
            :dense="true"
            :height="42"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
            no-data-text="暂无数据"
          ></v-autocomplete>
          <v-text-field
              v-else-if="item.value === 'transIntroduceType'"
              v-model="recommandTypeMap[transactionInfo[item.value]]"
              :label="item.label"
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="42"
            ></v-text-field>
          <v-text-field
            v-model="transactionInfo[item.value]"
              :label="item.label"
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="42"
              v-else
            ></v-text-field>
        </div>
        <div :span="4" style="margin-bottom: 20px" class="item">
          <v-autocomplete
            v-model="transactionInfo['assistDealCrmUserId']"
            :items="assistDealList"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="'协助成交人员'"
            outlined
            item-text="assistDealUserName"
            item-value="assistDealCrmUserId"
            :dense="true"
            :height="42"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
            no-data-text="暂无数据"
            :disabled="transactionInfo.isRecommand == 1"
            @change="changeAssistDeal"
          ></v-autocomplete>
        </div>
      </div>
  </div>
</template>

<script>
import { recommandTypeMap } from '../../../constants';
export default {
  props: {
    transactionInfo: Object,
    assistDealList: Array
  },
  data(){
    return {
      // 成交信息
      makeList: [
        {label: "客户编号", value: "customerId"},
        {label: "商机编号", value: "ncCustomerUserId"},
        {label: "学员手机", value: "phoneNumber"},
        {label: "招生老师", value: "teacherRecruitName"},
        {label: "销售老师", value: "teacherSalesName"},
        {label: "成交校区", value: "dealSchoolName"},
        {label: "上课校区", value: "campusSchoolName"},
        {label: "转介绍", value: "isRecommandName"}, //是否是转介绍
        {label: "转介绍类型", value: "transIntroduceType"},
        {label: "转介绍老师", value: "teacherRecommandName"},
        {label: "公开课类型", value: "openClassType"},
        {label: "是否外派", value: "lecturerAssigned"}, //是否外派
        {label: "讲师", value: "teacherLecturerName"},
        {label: "流量经纪人", value: "economicFlowPartner"},
        {label: "流量合伙人", value: "flowPartner"},
        {label: "促单形式", value: "promotionForm"},
        {label: "招生途径", value: "channelsTypeName"},
        {label: "46分成", value: "isFourSix"}, //是否46分成
      ],
      recommandTypeMap
    }
  },
  methods: {
    // 更改协助成交人员
    changeAssistDeal() {
      console.log(this.transactionInfo.assistDealUserName)
      if(this.transactionInfo.assistDealCrmUserId !== "" && this.transactionInfo.assistDealCrmUserId !== null){
        this.assistDealList.forEach(el => {
          if(el.assistDealCrmUserId === this.transactionInfo.assistDealCrmUserId){
            this.transactionInfo.assistDealNcUserId = el.assistDealNcUserId
            this.transactionInfo.assistDealUserName = el.assistDealUserName
          }
        });
      }
      console.log(this.transactionInfo.assistDealUserName)
    },
  }
}
</script>

<style lang="scss" scoped>
.deal-info-wrap {
  background: #fff;
  width: 94%;
  margin: 0 auto;

  .dealInfoBox {
    margin-top: 18px;
    display: flex;

    span {
      color: #333;
    }

    .dealInfoBoxInput {
      margin-right: 0;
    }

    .positionLeft {
      ::v-deep .el-form-item__label {
        text-align: left;
      }
    }
  }
}
.dealInfoList{
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 282px;
    margin-right: 24px;
  }
}
</style>